<template>
  <div>
    <v-row>
      <v-col cols="3" md="2" lg="1">
        <v-text-field dense type="number" v-model="computedExtraSeats" @input="recalculatePrice" required outlined
          hide-details min="1" max="1000000" />
      </v-col>
      <v-col cols="6" lg="3" class="pl-0">
        <span class="d-flex h-100 align-center pl-0">extra {{ computedExtraSeats > 1 ? 'gebruikers' : 'gebruiker'
          }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="12" md="7" lg="5" xl="3">
        <v-divider />
        <div class="pa-1">
          <div class="d-inline-block">
            <p class="ma-0">Kostprijs per gebruiker</p>
            <p class="ma-0">Extra kost nieuwe gebruikers</p>
            <p class="ma-0">Totaal nieuwe kost per maand</p>
            <br>
            <p class="ma-0"><strong>Direct te betalen:</strong></p>
            <p class="ma-0"><span v-if="!$vuetify.breakpoint.xsOnly">Aanpassing van </span>
              {{ format(new Date(activeSubscription.startDate), "dd-MM-yyyy") }} tot
              {{ format(getNextBillingDate, "dd-MM-yyyy") }}</p>
            <p class="ma-0">{{ getMonthsUntilNextBillingDate }} maanden x €{{ getExtraCostNewSeats }} (excl.
              {{ vatPercentage }}% btw)</p>
          </div>
          <div class="d-inline-block float-right">
            <p class="ma-0">€{{ activeSubscription.price.toFixed(2) }}</p>
            <p class="ma-0">€{{ getExtraCostNewSeats }}</p>
            <p class="ma-0">€{{ getNewLicenseCost }}</p>
            <br>
            <br>
            <br>
            <p class="ma-0">€{{ priceToPay.toFixed(2) }}</p>
          </div>
        </div>
        <v-divider />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-0">
        <payment-selection v-model="paymentMethod" />
      </v-col>
    </v-row>
    <v-col cols="12">
      <EulaCheckbox v-model="eulaAccepted" class="mb-3"/>
    </v-col>
    <v-row class="mt-6">
      <v-col class="pt-0">
        <v-btn :disabled="!paymentMethod || computedExtraSeats <= 0 || !eulaAccepted" small color="secondary"
          class="text-none" @click="buySeats">
          Betaal
        </v-btn>
        <v-btn small class="text-none ml-2" @click="cancel">Annuleer</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PaymentSelection from "@/components/payment/PaymentSelection";
import publicIp from "public-ip";
import format from "date-fns/format";
import { differenceInMonths } from "date-fns";
import { getVatPercentage } from "@/shared/utils/priceUtils";
import { getRequiredRules } from "@/shared/utils/inputRulesUtils";
import EulaCheckbox from "@/components/shared/fields/EulaCheckbox.vue";


export default {
  name: "ExtraSeats",
  components: { PaymentSelection,EulaCheckbox },
  props: {
    activeSubscription: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      extraSeats: 1,
      paymentMethod: null,
      format: format,
      priceToPay: 0.0,
      eulaAccepted: false
    }
  },
  created() {
    this.recalculatePrice()
  },
  methods: {
    getRequiredRules,
    cancel() {
      this.$emit('input', false)
    },
    async buySeats() {
      const ipAddress = await publicIp.v4()
        .then((result) => this.ipAddress = result)
        .catch(() => this.ipAddress = "192.168.0.1");

      const seatsPaymentRequest = {
        seats: this.computedExtraSeats,
        ipAddress: ipAddress,
        expectedPrice: this.getPriceToPay,
        paymentMethod: this.paymentMethod,
        successReturnUrl: process.env.VUE_APP_PAYMENT_SEATS_URL_SUCCESS +
          "&price=" + this.expectedPrice +
          "&amount=" + this.computedExtraSeats +
          "&organizationId=" + this.$store.getters["authModule/getOrganizationId"] +
          "&paymentMethod=" + this.paymentMethod +
          "&source=extraSeats",
        cancelReturnUrl: process.env.VUE_APP_PAYMENT_SEATS_URL_CANCEL,
        failureReturnUrl: process.env.VUE_APP_PAYMENT_SEATS_URL_FAILURE
      };

      this.$store.dispatch("paymentModule/seatsPaymentRequest", seatsPaymentRequest)
        .then((result) => window.location.href = result);
    },
    recalculatePrice() {
      if (this.computedExtraSeats && this.computedExtraSeats > 0) {
        this.$store.dispatch("paymentModule/calculatePriceForExtraSeats", this.computedExtraSeats)
          .then((success) => this.priceToPay = success?.data, () => {
          })
      }
    }
  },
  computed: {
    getNewLicenseCost() {
      const totalNewSeats = this.computedExtraSeats + this.activeSubscription.totalSeats;
      return (this.activeSubscription.price * totalNewSeats).toFixed(2)
    },
    getExtraCostNewSeats() {
      return (this.activeSubscription.price * this.computedExtraSeats).toFixed(2)
    },
    getPriceToPay() {
      // price per month
      // multiply with extra requested seats
      // multiply with how many months the current subscription is still valid
      return (this.activeSubscription.price * this.computedExtraSeats * this.getMonthsUntilNextBillingDate).toFixed((2));
    },
    getNextBillingDate() {
      // TODO should be adapted when using subscriptions
      return new Date(this.activeSubscription.endDate)
    },
    getMonthsUntilNextBillingDate() {
      // TODO move all calcs to backend BUILDBASE-598
      // calculate the months between today and the next billing date
      // to know for how months the client should pay
      // result should be rounded up? meaning/
      // - 02/02 until 02/03 should give 1 month,
      // - 02/02 until 05/03 should give 2 months
      // - 02/02 until 01/03 should give 1 month
      const currentDateRoundedUp = new Date()
      if (currentDateRoundedUp.getDate() > this.getNextBillingDate.getDate()) {
        // force round up
        currentDateRoundedUp.setDate(this.getNextBillingDate.getDate())
      }
      return differenceInMonths(this.getNextBillingDate, currentDateRoundedUp) + 1;
    },
    vatPercentage() {
      return getVatPercentage(this.$store.state.organizationModule.address.countryCode)
    },
    computedExtraSeats: {
      get() {
        return this.extraSeats
      },
      set(newValue) {
        this.extraSeats = Math.round(Math.min(Math.max(1, newValue), 1000000))
      }
    }
  }
}
</script>