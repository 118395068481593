<template>
    <v-card tile flat>
        <v-card-title id="subscription">Abonnement</v-card-title>
        <v-divider/>
        <PromiseContainer :promises.sync="promises">
            <template v-slot:default>
                <v-card-text class="text--primary">
                    <p class="ma-0">
                        <v-icon>mdi-account-check</v-icon>
                        Aantal gebruikers: {{ activeSubscription.seatsUsed }}/{{ activeSubscription.totalSeats }}<span v-if="!isTrial"> — <span
                        class="clickable" @click="requestExtraSeats=!requestExtraSeats">Extra gebruikers toevoegen</span> </span>
                    </p>
                    <p class="my-1">Maandelijks kostprijs €{{ getMonthlyCost }}</p>
                    <extra-seats class="mt-4" v-if="requestExtraSeats" v-model="requestExtraSeats"
                                :active-subscription="activeSubscription"/>
                    <v-row>
                        <v-col cols="12" lg="4" md="6">
                            <v-subheader class="pl-0">Modules</v-subheader>
                            <v-list class="pa-0" shaped disabled>
                                <v-list-item-group>
                                    <v-list-item
                                            v-for="module in getModules" :key="module">
                                        <v-list-item-icon>
                                            <v-icon>mdi-view-module</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="module"/>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                        <v-col cols="12" lg="4"  md="6">
                            <v-subheader class="pl-0">Geldigheid</v-subheader>
                            <v-timeline dense>
                                <v-timeline-item
                                        v-for="(item, index) in getValidity"
                                        :key="item.label"
                                        :color="item.color"
                                        :hide-dot="item.extendOption"
                                        :class="{ 'pb-0': index === getValidity.length - 1 }"
                                        small>
                                    <div>
                                        <span v-if="!item.extendOption"><strong>{{
                                            item.label
                                            }}</strong> {{ item.date ? format(item.date, dateFormat) : null }}</span>
                                        <span v-else-if="item.extendOption" class="clickable"
                                            @click="requestExtensionOrTrialToPaid">{{ item.label }}</span>
                                        <span v-if="item.extendOption && requestExtend && getMaxAmountOfMonths === 0">
                        <v-banner class="banner-orange" rounded>
                            Uw abonnement is nog geldig voor 24 maanden en kan niet verder verlengd worden
                        </v-banner>
                        </span>
                                        <span v-if="item.extendOption && requestExtend && getMaxAmountOfMonths !== 0">
                        <v-form v-model="validAmountOfMonths" @submit.prevent>
                            <v-text-field id="extendInputField" v-model="amountOfMonths"
                                        autofocus required outlined dense type="number"
                                        min="1" :max="getMaxAmountOfMonths"
                                        :rules="amountOfMonthsRules"
                                        @input="recalculatePrice"
                            />
                        </v-form>
                        <p>{{ amountOfMonths }} {{ amountOfMonths > 1 ? 'maanden' : 'maand' }} x €{{
                            getMonthlyCost
                            }} = €{{ expectedPrice }} (excl. {{ vatPercentage }}% btw)</p>
                        <payment-selection v-model="paymentMethod" class="mb-3"/>
                        <EulaCheckbox v-model="eulaAccepted" class="mb-3"/>
                        <v-btn :disabled="!paymentMethod || !validAmountOfMonths || !eulaAccepted" small color="secondary"
                                class="text-none" @click="buyExtensionOrRenewal">Betaal</v-btn>
                        <v-btn small class="text-none ml-2" @click="requestExtend=false">Annuleer</v-btn>
                        </span>
                                    </div>
                                </v-timeline-item>
                            </v-timeline>
                        </v-col>
                    </v-row>
                </v-card-text>
            </template>
        </PromiseContainer>
    </v-card>
</template>

<script>

import compareAsc from "date-fns/compareAsc";
import {translateModuleName} from "@/shared/utils/translateUtils";
import format from "date-fns/format";
import PromiseContainer from "@/components/shared/PromiseContainer.vue";
import {add, addYears, differenceInMonths, isAfter} from "date-fns";
import ExtraSeats from "@/components/settings/ExtraSeats";
import publicIp from "public-ip";
import store from "@/store";
import PaymentSelection from "@/components/payment/PaymentSelection";
import {getVatPercentage} from "@/shared/utils/priceUtils";
import routeNames from "@/router/RouteNames";
import { getRequiredRules } from "@/shared/utils/inputRulesUtils";
import EulaCheckbox from "@/components/shared/fields/EulaCheckbox.vue";


export default {
    name: "SubscriptionInfo",
    components: {ExtraSeats, PaymentSelection, PromiseContainer,EulaCheckbox},
    data() {
        return {
            format: format,
            requestExtraSeats: false,
            requestExtend: false,
            newEndDate: null,
            paymentMethod: null,
            amountOfMonths: 1,
            validAmountOfMonths: false,
            add: add,
            expectedPrice: 0.0,
            promises: [
                // re fetch active subscription info
                // whenever user extends a subscription, he is currently navigated to the settings view
                // ensure that the active subscription info get refreshed
                // TODO, create specific route view for this, only do this fetch action on this specific route view - BUILDBASE-818
                this.$store.dispatch('organizationModule/fetchSubscriptionStatus')
                    .then(data => {
                        this.isTrial = data.trial
                    }),

                // update date format
                // add 'kost prijs' to verleng/vernieuw abo
                this.$store.dispatch('organizationModule/fetchAddress')
                    .then(data => this.countryCode = data.countryCode),
                this.$store.dispatch('organizationModule/fetchActiveSubscriptionInfo')
                    .then(data => {
                        this.activeSubscription = data
                        this.recalculatePrice()
                    })
            ],
            isTrial: null,
            countryCode: null,
            activeSubscription: {},
            eulaAccepted: false
        }
    },
    created() {
        // if returning from redirection
        if(this.$route.query.price) {
            this.organizationId = this.$route.query.organizationId
            const eventParams = {
                transaction_id: this.$route.query.organizationId,
                value: this.$route.query.price,
                currency: 'EUR',
                items: [
                    {
                    name: 'abonnement',
                    category: this.$route.query.source,
                    quantity: this.$route.query.amount,
                    price: this.$route.query.price
                    }
                ]
            };
            this.$gtag.event("purchase", eventParams);
        }
    },
    methods: {
        getRequiredRules,
        buyExtensionOrRenewal() {
            // checking if it was a trial to paid extension happens in backend
            this.promises.push(publicIp.v4()
                .then((result) => this.ipAddress = result)
                .catch(() => this.ipAddress = "192.168.0.1")
                .finally(() => {
                    const extendOrRenewalPaymentRequest = {
                        organizationId: store.getters["authModule/getOrganizationId"],
                        amountOfMonths: this.amountOfMonths,
                        ipAddress: this.ipAddress,
                        expectedPrice: this.expectedPrice,
                        paymentMethod: this.paymentMethod,
                        successReturnUrl: process.env.VUE_APP_PAYMENT_EXTENSION_URL_SUCCESS +
                            "&price=" + this.expectedPrice +
                            "&organizationId=" + this.$store.getters["authModule/getOrganizationId"] +
                            "&source=" + (this.isExtension ? "extension" : "renewal") +
                            "&paymentMethod=" + this.paymentMethod +
                            "&amount=" + this.amountOfMonths,
                        cancelReturnUrl: process.env.VUE_APP_PAYMENT_EXTENSION_URL_CANCEL,
                        failureReturnUrl: process.env.VUE_APP_PAYMENT_EXTENSION_URL_FAILURE
                    };                    
                    this.promises.push(this.$store.dispatch("paymentModule/extendOrRenewalPaymentRequest", extendOrRenewalPaymentRequest)
                        .then((result) => window.location.href = result))
                })
            );
        },
        requestExtensionOrTrialToPaid() {
            if (this.isTrial) {
                this.$router.push({name: routeNames.TRIAL_TO_PAID})
            } else {
                this.requestExtend = !this.requestExtend;
                this.$vuetify.goTo(this.scrollBottom)
            }
        },
        recalculatePrice() {
            if(!!this.amountOfMonths && this.amountOfMonths > 0) {
                this.promises.push(
                    this.$store.dispatch('paymentModule/calculatePriceForExtensionOrRenewal', this.amountOfMonths)
                    .then((success) => this.expectedPrice = success?.data)
                )
            }
        }
    },
    computed: {
        getModules() {
            if (!this.activeSubscription?.modules) {
                return null
            }
            return this.activeSubscription.modules.map(module => translateModuleName(module))
        },
        getValidity() {
            return [
                {
                    label: 'Start datum',
                    date: this.activeSubscription.startDate ? new Date(this.activeSubscription.startDate) : null,
                    color: 'green',
                },
                {
                    label: 'Eind datum',
                    date: this.activeSubscription.endDate ? new Date(this.activeSubscription.endDate) : null,
                    color: 'green',
                },
                {
                    label: 'Vandaag',
                    date: new Date(),
                    color: 'grey',
                },
                {
                    label: `${this.isTrial ? 'Koop' : this.isExtension ? 'Verleng' : 'Vernieuw'} abonnement`,
                    extendOption: true,
                    date:  this.activeSubscription.endDate ? add(this.isExtension ? new Date(this.activeSubscription.endDate) : new Date(), {days: 1}): null,
                }
            ].sort((a, b) => compareAsc(a.date, b.date))
        },
        isExtension() {
            // check if the user is extending or renewing (= create new subscription as it is expired) his subscription
            return isAfter(new Date(this.activeSubscription.endDate), new Date())
        },
        getMonthlyCost() {
            return (this.activeSubscription.price * this.activeSubscription.totalSeats).toFixed(2)
        },
        scrollBottom() {
            return document.body.scrollHeight
        },
        vatPercentage() {
            return getVatPercentage(this.countryCode)
        },
        getMaxAmountOfMonths() {
            if (this.isExtension) {
                // if you have a running subscription, calculate the difference between the endDate of the subscription and the current date 2 years from now
                let difference = differenceInMonths(addYears(new Date(), 2), new Date(this.activeSubscription.endDate))
                if (difference > 0 && difference < 25) {
                    // if the difference is between 0 and 25, you can extend for 1 to difference amount of months with a max of 12 months
                    return difference > 12 ? 12 : difference
                } else {
                    // otherwise you can't extend and get a warning that your current subscription is running for longer than 2 years from now.
                    return 0
                }
            } else {
                // if your last subscription has ended, you can renew your subscription for a maximum of 12 months.
                return 12
            }
        },
        amountOfMonthsRules() {
            const rules = []
            rules.push(v => v > 0 || 'Abonnement moet ten minste verlengd worden voor 1 maand')
            if (this.amountOfMonths > this.getMaxAmountOfMonths) {
                rules.push(v => v <= this.getMaxAmountOfMonths || this.isExtension ? 'Abonnement kan maximaal voor ' + this.getMaxAmountOfMonths + ' maanden verlengd worden' : 'Een nieuw abonnement kan maximaal voor ' + this.getMaxAmountOfMonths + ' maanden genomen worden')
            }
            return rules
        },
        dateFormat() {
            return this.$store.getters["parameterModule/getDateFormat"]
        }
    },
}
</script>
